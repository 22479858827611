<template>
  <a-carousel autoplay>
    <div>
      <HostLayoutContainer class="banner4" :style="{ height: '6.54rem' }">
        <!-- <HostLayoutItem :style="{ height: '6.54rem' }">
          <div class="banner__title">企业用云的有力帮手</div>
          <div class="banner__button">
            <div class="banner__button-text">WELCOME TO Ubang</div>
          </div>
        </HostLayoutItem> -->
      </HostLayoutContainer>
    </div>
    <div>
      <HostLayoutContainer class="banner1" :style="{ height: '6.54rem' }">
        <HostLayoutItem :style="{ height: '6.54rem' }">
          <div class="banner__title">企业用云的有力帮手</div>
          <div class="banner__button">
            <div class="banner__button-text">WELCOME TO Ubang</div>
          </div>
        </HostLayoutItem>
      </HostLayoutContainer>
    </div>
    <!-- <div>
      <HostLayoutContainer class="banner2" :style="{ height: '6.54rem' }">
        <HostLayoutItem :style="{ height: '6.54rem' }">
          <div class="banner__title">企业用云的有力帮手</div>
          <div class="banner__button">
            <div class="banner__button-text">WELCOME TO Ubang</div>
          </div>
        </HostLayoutItem>
      </HostLayoutContainer>
    </div>
    <div>
      <HostLayoutContainer class="banner3" :style="{ height: '6.54rem' }">
        <HostLayoutItem :style="{ height: '6.54rem' }">
          <div class="banner__title">企业用云的有力帮手</div>
          <div class="banner__button">
            <div class="banner__button-text">WELCOME TO Ubang</div>
          </div>
        </HostLayoutItem>
      </HostLayoutContainer>
    </div> -->
  </a-carousel>
</template>

<style lang="scss" scoped>
.banner1 {
  background-image: url("/assets/home/banner_1@3x.jpeg");
  background-size: cover;
}
.banner2 {
  background-image: url("/assets/home/banner_2@3x.png");
  background-size: cover;
}
.banner3 {
  background-image: url("/assets/home/banner_3@3x.png");
  background-size: cover;
}
.banner4 {
  background-image: url("/assets/home/banner_4.png");
  background-size: cover;
}
.banner__title {
  display: flex;
  justify-content: center;

  margin-top: 1.6rem;
  margin-bottom: 0.52rem;

  font-size: 0.74rem;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.11rem;
}
.banner__button {
  display: flex;
  justify-content: center;
}
.banner__button-text {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 337px;
  height: 64px;
  background: #1e88f5;
  box-shadow: 0px 0px 30px 0px rgba(30, 136, 245, 0.9);
  border-radius: 4px;

  font-size: 20px;
  font-weight: normal;
  color: #fff;
  line-height: 23px;
  letter-spacing: 1px;
}
</style>
